import React from "react";
import "./Dashboard.css";
import { Line } from 'react-chartjs-2';

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'My First dataset',
        fill: false,
        
        lineTension: 0.1,
        backgroundColor: 'rgba(38, 121, 173,0.8)',
        borderColor: 'rgba(38, 121, 173,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(38, 121, 173,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(38, 121, 173,1)',
        pointHoverBorderColor: 'rgba(38, 121, 173,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
  };

export default function Dashboard(){
    return (
        <div className="view">

            <div className="chart">
                <Line data={data} />
            </div>

        </div>
    );
}