import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { IdentityContext } from "../state/Identity.Context";

export default function AuthenticatedRoute({children, ...rest} : any){
    const { pathname, search } = useLocation();
    const { isAuthenticated } = useContext(IdentityContext);

    return (
        <Route {...rest}>
            {isAuthenticated ? (
                children
            ) : (
                <Redirect to={
                    `/login?redirect=${pathname}${search}`
                } />
            )}
        </Route>
    );
}