export default function avatar(label:string|undefined, large:boolean=false){
    if (label!=undefined){
        let acronym = label.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'');

        if (large){
            return acronym.substr(0,3);
        }else{
            return acronym.substr(0,2);
        }
    }else{
        return (large? "UKN" : "UK");
    }
}