import React, { useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { IdentityContext } from "./../state/Identity.Context";
import { IdentityService } from "./../services/Identity.Service";
import "./Navigation.css";
import { AccountInterface } from "../state/Identity.d";
import Avatar from "./Avatar";

export default function Navigation(){
    const history = useHistory();
    const { isAuthenticated, profile, setIdentity } = useContext(IdentityContext);
    
    function handleLogout(event:any){
        event.preventDefault();

        //Log the user out.
        IdentityService.logout();
        
        //Update the identity
        setIdentity(false, false, null);

        //Redirect to login
        history.push("/Login");
    }

    function switchAccount(account:AccountInterface){
        //Update local storage state.
        localStorage.setItem('account', account.id);

        //Reload
        window.location.reload();
    }

    return (
        <div className="navigation">
            <div className="section box logo">
                <Link to="https://www.invoicebank.nz">
                    <i className="fal fa-receipt"></i>
                </Link>
            </div>
            {isAuthenticated ?    
                <>       
                    <ul className="section links">
                        <li className="section">
                            <NavLink to="/" exact={true} activeClassName="active">
                                <i className="bounce fal fa-tachometer-alt-fast"></i>
                            </NavLink>
                        </li>
                        <li className="section">
                            <NavLink to="/Contacts" activeClassName="active">
                                <i className="bounce fal fa-address-card"></i>
                            </NavLink>
                        </li>
                        <li className="section">
                            <NavLink to="/Invoices" activeClassName="active">
                                <i className="bounce fal fa-file-invoice"></i>
                            </NavLink>
                        </li>
                        <li className="section">
                            <NavLink to="/Reports" activeClassName="active">
                                <i className="bounce fal fa-analytics"></i>
                            </NavLink>
                        </li>
                    </ul>
                    <div className="section box">
                        <Link to="/Settings">
                            <i className="fal fa-cogs"></i>
                        </Link>
                    </div>
                </>
            :
                <></>
            }
        </div>

    );

    // return (
    //     <div className="navigation">
    //         <div className="main">
    //             <div className="avatar">{Avatar(profile?.currentAccount.label, true)}</div>
    //             <div className="company">
    //                 <span className="text-trim">{profile?.currentAccount?.label}</span>
    //                 <i className="fal fa-arrow-circle-down"></i>
    //                 <div className="mask"></div>                    
    //                 <ol>
    //                     <li className="spacer"></li>
    //                     <li className="link">
    //                         <Link to="/Settings">Settings</Link>
    //                     </li>
    //                     <li className="link">
    //                         <Link to="/Subscription">Subscription and billing</Link>
    //                     </li>
    //                     <li className="spacer"></li>
    //                     <li className="line"></li>
    //                     <li className="spacer"></li>
    //                     {profile?.accounts.map((a,b) => 
    //                         <li className="switch" key={b} onClick={()=>switchAccount(a)}>
    //                             <div className="avatar">{Avatar(profile?.accounts[b].label)}</div>
    //                             <span className="text-trim">{profile?.accounts[b].label}</span>
    //                         </li>
    //                     )}
    //                     <li className="spacer"></li>
    //                     <li className="spacer"></li>
    //                     <li className="line"></li>
    //                     <li className="spacer"></li>
    //                     <li className="add">
    //                         <Link to="/New-Account">
    //                             <i className="fal fa-plus-square"></i>
    //                             <span>Add a new account</span>
    //                             <div className="clear"></div>
    //                         </Link>
    //                     </li>
    //                     <li className="spacer"></li>
    //                 </ol>
    //             </div>

    //             {isAuthenticated ? 
    //                 <div className="profile">
    //                     <b>{Avatar(profile?.particulars?.displayName)}</b>
    //                     <ol>
    //                         <li className="spacer"></li>
    //                         <li className="link">
    //                             <Link to="/Profile">My Details</Link>
    //                         </li>
    //                         <li className="link">
    //                             <a href="#0" onClick={handleLogout}>Log Out</a>
    //                         </li>
    //                         <li className="spacer"></li>
    //                     </ol>                    
    //                 </div>
    //             : 
    //                 <></> 
    //             }
    //         </div>
    //         <div className="side">
    //             <ul>
    //                 <li>
    //                     <NavLink to="/" exact={true} activeClassName="active">
    //                         <i className="fal fa-tachometer-alt-fast"></i>
    //                     </NavLink>
    //                 </li>
    //                 <li>
    //                     <NavLink to="/Contacts" activeClassName="active">
    //                         <i className="fal fa-address-card"></i>
    //                     </NavLink>
    //                 </li>
    //                 <li>
    //                     <NavLink to="/Invoices" activeClassName="active">
    //                         <i className="fal fa-file-invoice"></i>
    //                     </NavLink>
    //                 </li>
    //                 <li>
    //                     <NavLink to="/Reports" activeClassName="active">
    //                         <i className="fal fa-analytics"></i>
    //                     </NavLink>
    //                 </li>
    //             </ul>
    //         </div>
    //     </div>
    // );
}