import React from "react";
import "./Reports.css";

export default function Reports(){
    return (
        <div className="view">
            <h1>Reports!</h1>
            <span>Select view on report metrics</span>
        </div>
    );
}