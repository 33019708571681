import React, { useEffect } from 'react';
import { useIdentity } from "./state/Identity.Hook";
import { IdentityContext } from "./state/Identity.Context";
import { IdentityService } from "./services/Identity.Service";
import Routes from "./routes/Routes";
import Navigation from "./shell/Navigation";
import './App.css';

function App() {
  const identity = useIdentity();

  useEffect(() => {
    //Set Authenticating
    identity.setIdentity(false, true, null);

    //Fetch the user's profile.
    IdentityService.get().then(a=>{
      //Update Identity
      identity.setIdentity(true, false, a);
    }).catch(a=>{
      identity.setIdentity(false, false, null);
    });
    // eslint-disable-next-line
  }, []);

  return (
    (!identity.isAuthenticating
      ?
      <IdentityContext.Provider value={identity}>
        <div className="container">
          <Navigation />
          <Routes />
        </div>
      </IdentityContext.Provider>
      :
      <div className="loading">Loading...</div>
    )
    // (!identity.isAuthenticating 
    //   ?
    //   <IdentityContext.Provider value={identity}>
    //     <div className={identity.isAuthenticated ? 'container auth' : 'container'}>
    //       {identity.isAuthenticated ? <Navigation /> : <></> }
    //       <Routes />
    //       {identity.isAuthenticated ? <div className="copyright">Copyright 2021 © InvoiceBank Ltd<br />Made with <i className="fas fa-heart"></i> by <a href="http://www.organica.nz" target="_blank">Organica</a></div> : <></> }
    //     </div>
    //   </IdentityContext.Provider>
    //   :
    //   <div className="loading"></div>
    // )
  );
}
export default App; 