import React from "react";
import { Bar } from "react-chartjs-2";
import { ContactFinancial } from "./List.d";

type VolumeData = {
    financial:Array<ContactFinancial>
}

export default function Volume({financial} : VolumeData){
    //Extract Labels
    const labels = financial?.map((a,b) => a.event);

    //Extract Values
    const values = financial.map((a,b) => a.amount);

    //Setup Chart
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Volume',
                data: values,
                fill: false      
            }
        ]
    };

    //Set Options
    const options = {
        responsive: true,
        barThickness:6,
        layout:{
            padding: 0
        },
        elements:{
            bar:{
                barThickness:1,
                borderWidth:1, 
                borderRadius:4,
                backgroundColor:"rgba(38, 121, 173, 0.6)",
                borderColor:"rgba(38, 121, 173, 0.6)",                               
                hoverBackgroundColor:"rgba(38, 121, 173, 1)",
                hoverBorderColor:"rgba(38, 121, 173, 1)",
                
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid:{
                    tickLength:0
                },
                ticks:{
                    display:false
                }
            },
            x: {
                display:false,
                grid:{
                    tickLength:0
                },
                ticks:{
                    display:false
                }
            }
        },
        plugins:{
            legend:{
                display: false
            },
            tooltip:{
                enabled:false
            }
        }
    };

    return (
        <Bar height={20} width={100} data={data} options={options} />
    );
}