import React from "react";
import "./ActionButton.css";

export default function ActionButton({
    type = "", //submit, none
    isLoading = false,
    className = "",
    disabled = false,
    label = "",
    ...props
}){
    return (
        type === "submit" ?
        <input 
            className={`action-button ${className}`} 
            type="submit" 
            disabled={disabled || isLoading} 
            value={isLoading? "Processing..." : label}
            {...props}
        /> : <></>
    );
}