const dev = {
    debug:false,
    apiMock: "http://localhost:5001",
    api: "https://api.invoicebank.nz"
};

const prod = {
    debug:false,
    apiMock: "https://api.invoicebank.nz",
    api: "https://api.invoicebank.nz"
};

//Default to dev if not set
const env = process.env.REACT_APP_STAGE;
const config = (env ==='prod' ? prod : dev);

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};