import React from "react";
import "./Profile.css";

export default function Profile(){
    return (
        <div className="view">
            <h1>My details!</h1>
            <span>Maintain your account details, password, etc....</span>
        </div>
    );
}