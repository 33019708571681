import React from "react";
import config from "./../config";
import { IdentityInterface, ProfileInterface} from "./Identity.d";

export const useIdentity = ():IdentityInterface => {
    const [isAuthenticated, setAuthenticatedSetter] = React.useState(false);
    const [isAuthenticating, setAuthenticatingSetter] = React.useState(true);
    const [profile, setDataSetter] = React.useState<ProfileInterface|null>(null);

    const setIdentity = React.useCallback((isAuthenticated:boolean, isAuthenticating:boolean, profile:ProfileInterface|null): void => {
        if (config.debug){
            console.log("Hooks - Authenticated Setter", isAuthenticated);
            console.log("Hooks - Authenticating Setter", isAuthenticating);
            console.log("Hooks - Data Setter", profile);
        }
        
        setAuthenticatedSetter(isAuthenticated);
        setAuthenticatingSetter(isAuthenticating);
        setDataSetter(profile);
    }, []);

    return {
        isAuthenticated,
        isAuthenticating,
        profile,
        setIdentity
    };
};