import React from "react";
import Lottie from 'react-lottie-player'

type Metadata = {
  lottie:object,
  displayWidth:number,
  displayHeight:number
};

export default function Animation({ lottie, displayWidth, displayHeight } : Metadata) {
  return (
    <div>
      <Lottie
        animationData={lottie}
        loop
        play
        style={{ width: displayWidth, height: displayHeight }} />
    </div>
  );
};