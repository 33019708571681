import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { IdentityContext } from "../state/Identity.Context";

function querystring(name:any, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);
  
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }
  
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

export default function UnauthenticatedRoute({children, ...rest} : any){
  const { isAuthenticated } = useContext(IdentityContext);
  const redirect = querystring("redirect");
  return (
      <Route {...rest}>
          {!isAuthenticated ? (
              children
          ) : (
              <Redirect to={redirect === "" || redirect === null ? "/" : redirect} />
          )}
      </Route>
  );
}