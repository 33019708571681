import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { IdentityContext } from "./../../state/Identity.Context";
import { IdentityService } from "../../services/Identity.Service";
import { useFormFields } from "../../libs/FormFieldsLib";
import ActionButton from "../../components/Buttons/ActionButton";
import "./Login.css";

export default function Login(){
    const { setIdentity } = useContext(IdentityContext);
    const [isLoading, setIsLoading ] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        email:"",
        password:""
    });

    function validateForm() {
        return fields.email.length > 0 && fields.password.length > 0;
    }

    async function handleSubmit(event:any) {
        event.preventDefault();

        //Reset and loading state.
        setIdentity(false, false, null);
        setIsLoading(true);

        await IdentityService.login(fields.email, fields.password).then(async a=>{
            //Invoke Profile
            await IdentityService.get().then(b=>{
                setIdentity(true, false, b);
            }).catch(c=>{
                setIdentity(false, false, null);
                setIsLoading(false);
            });
        }).catch(b=>{
            setIdentity(false, false, null);
            setIsLoading(false);
        });     
    }

    return (
        <div className="login">
            <div className="stage">
                <div className="text">sign in</div>
            </div>
            <div className="aside">
                <div className="inner">
                    <h1>Sign In</h1>
                    <p>Don't have an account yet? <Link to="/Sign-Up">Sign Up</Link></p>
                    <form onSubmit={handleSubmit}>
                        <div className="group">
                            <p>Email Address:</p>
                            <input 
                                id="email"
                                className="input"
                                autoFocus
                                type="email" 
                                value={fields.email} 
                                onChange={handleFieldChange} 
                            />
                        </div>
                        <div className="group">
                            <p>Password:</p>
                            <input 
                                id="password"
                                className="input"
                                type="password" 
                                value={fields.password} 
                                onChange={handleFieldChange} 
                            />
                        </div>   
                        <ActionButton
                            type="submit"
                            className="full-width offset"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                            label="Login"
                        >
                        </ActionButton>                     
                        <p><Link to="/Forgot">Forgot your password?</Link></p>
                    </form>
                </div>
            </div>
        </div>
    );
}