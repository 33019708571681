import React, { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "./InvoiceDetail.css";
import "./../../styles/Toolbar.css";
import DropButton from "../../components/Buttons/DropButton";
import avatar from "../../shell/Avatar";

interface Params {
    id:string
}

export default function InvoiceDetail(){    
    const { id } = useParams<Params>();
    const [isLoading, setLoading] = useState(false);

    return (
        <div className="view">   

            <div className="toolbar">
                <div className="breadcrumbs">
                    <ul>
                        <li>
                            <Link to="/Invoices">
                                Invoices
                            </Link>
                        </li>
                        <li>
                            <i className="fal fa-angle-double-right"></i>
                        </li>
                        <li>
                            #INV-018351
                        </li>
                    </ul>
                </div>

                <div className="controls">
                    
                    <DropButton action={true} isLoading={isLoading} items={[]} />

                </div>
            </div>

            <div className="columns">
                <div className="col content">

                    <div className="columns">
                        <div className="col from">

                            <h4>From</h4>

                            <div className="card">
                                <p className="avatar">{avatar('Acme Corporation Limited', true)}</p>
                                <span className="text-trim">Acme Corporation Limited</span>
                                <span className="text-trim">Some other detail...</span>
                                <i className="fal fa-chevron-square-down"></i>
                            </div>

                            <div className="form-row">
                                <span>Address</span>
                                <p>784 Sleepy Fawn Bay, Auckland 1050, New Zealand</p>
                            </div>

                            <div className="form-row">
                                <span>Email</span>
                                <p>accounts@acme-corp.com</p>
                            </div>

                            <div className="form-row">
                                <span>Phone</span>
                                <p>(+64) 21 373 752</p>
                            </div>
                            
                        </div>
                        <div className="col to">
                            <h4>To</h4>

                            <div className="card">
                                <p className="avatar">{avatar('Organica Communications Limited', true)}</p>
                                <span className="text-trim">Organica Communications Limited</span>
                                <span className="text-trim">Some other detail...</span>
                                <i className="fal fa-chevron-square-down"></i>
                            </div>
                        </div>
                    </div>

                    <div className="table">
                        <ol>
                            <li className="header">
                                <div>
                                    <p>Item Description:</p>
                                </div>
                                <div>
                                    <p>Unit Cost:</p>
                                </div>
                                <div>
                                    <p>Discount:</p>
                                </div>
                                <div>
                                    <p>Quantity:</p>
                                </div>
                                <div>
                                    <p>Total:</p>
                                </div>
                            </li>
                            <li className="item">
                                <div>
                                    <p>Item Description:</p>
                                </div>
                                <div>
                                    <p>Unit Cost:</p>
                                </div>
                                <div>
                                    <p>Discount:</p>
                                </div>
                                <div>
                                    <p>Quantity:</p>
                                </div>
                                <div>
                                    <p>Total:</p>
                                </div>
                            </li>
                            <li className="item alt">
                                <div>
                                    <p>Item Description:</p>
                                </div>
                                <div>
                                    <p>Unit Cost:</p>
                                </div>
                                <div>
                                    <p>Discount:</p>
                                </div>
                                <div>
                                    <p>Quantity:</p>
                                </div>
                                <div>
                                    <p>Total:</p>
                                </div>
                            </li>
                            <li className="item">
                                <div>
                                    <p>Item Description:</p>
                                </div>
                                <div>
                                    <p>Unit Cost:</p>
                                </div>
                                <div>
                                    <p>Discount:</p>
                                </div>
                                <div>
                                    <p>Quantity:</p>
                                </div>
                                <div>
                                    <p>Total:</p>
                                </div>
                            </li>
                        </ol>
                    </div>
                    
                </div>

                <div className="col preview">
                    Preview panel.
                </div>
            </div>
            <div className="gutter"></div>            
        </div>
    );
}