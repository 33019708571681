import config from "../../../config";
import React, { useEffect, useState } from "react";
import {SearchResponse} from "./List.d";
import avatar from "../../../shell/Avatar";
import Volume from "./Volume";
import { Link } from "react-router-dom";
import { handleResponse } from '../../../helpers/HandleResponse';
import { IdentityService } from "../../../services/Identity.Service";
import Moment from 'react-moment';
import Animation from './../../../shell/Animation';
import errorAnimation from './../../../animations/error.json';
import "./../../../styles/Grid.css";
import "./List.css";

export default function List(){
    const size = 20;
    const page = 1;
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [contacts, setContacts] = useState<SearchResponse|null>(null);

    useEffect(() => {
        async function get(){
            await fetch(`${config.apiMock}/contacts?accountId=${IdentityService.getAccount()}&page=${page}&size=${size}`)
            .then(handleResponse)
            .then((a:SearchResponse)=>{
                setContacts(a);
                setLoading(false);
                setError(false);
                return a;
            }).catch(a=>{
                setLoading(false);
                setError(true);
            });
        }

        get();
    }, []);

    return (
        <div className="grid">
            <ul>
                <li className="header">
                    <div>
                        <p>Name:</p>
                        <sup>
                            <i className="fal fa-arrow-down"></i>
                        </sup>
                    </div>
                    <div>
                        <p>Contact Person:</p>
                        <sup></sup>
                    </div>
                    <div>
                        <p>Email:</p>
                        <sup></sup>
                    </div>
                    <div>
                        <p>Address:</p>
                        <sup></sup>
                    </div>                        
                    <div>
                        <p>Volume:</p>
                        <sup></sup>
                    </div> 
                    <div>
                        <p>Last Activity:</p>
                        <sup></sup>
                    </div>
                    <div className="actions">
                        <p></p>
                    </div>                       
                </li>   

                {isError ? 
                    <li className="error">
                        <div>
                            <Animation lottie={errorAnimation} displayHeight={350} displayWidth={450} />
                            <h3>Aww snap!</h3>
                            <span>It's embarrassing, we've seem to have hit a problem while fetching your details.<br />Sometimes, this may happen if you've lost your internet connection. In this case check your connection and try again.</span>
                        </div>
                    </li>
                :
                    <></>
                }      

                {isLoading && !isError ?
                    [...Array(size)].map((a,b) =>
                        <li className="item" key={b}>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>                            
                            <div className="actions">
                                <p className="skeleton"></p>
                            </div>
                        </li>
                    )                    
                :
                    contacts?.items.map((contact, index)=>(
                        <li className="item" key={index}>
                            <div>
                                <p>{contact.label}</p>
                            </div>
                            <div>
                                <p className="avatar">{avatar(contact?.contact?.label, false)}</p>
                                <p>{contact.contact.label}</p>
                            </div>
                            <div>
                                <p>{contact.particulars.email}</p>
                            </div>
                            <div>
                                <p className="clampTwoLines">{contact.particulars.address}</p>
                            </div>
                            <div>
                                <Volume financial={contact.financial} />
                            </div>
                            <div>
                                <p>
                                    <Moment date={contact.lastActivity} format="Do MMM YYYY [at] h:mm a" local />
                                </p>
                            </div>                            
                            <div className="actions">
                                <Link to={`/Contacts/${contact.id}`}>
                                    <i className="fal fa-chevron-right"></i>
                                </Link>
                            </div>
                        </li>
                    ))
                }
            </ul>
            {!isLoading && contacts && contacts?.count > 0 ?
                <div className="stats">{contacts?.count} items found.</div>
            :
                <></>
            }
            {!isLoading && contacts && contacts?.count > size ?
                <ol>
                    <li className="active">1</li>
                    <li>2</li>
                    <li>3</li>
                </ol>
            :
                <></>
            }            
            <div className="clear"></div>
        </div>
    );
}