import React from "react";
import "./Settings.css";

export default function Settings(){
    return (
        <div className="view">
            <h1>Settings!</h1>
            <span>Administer account wide settings, users, invoice templates, notification preferences, etc...</span>
        </div>
    );
}