import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

export default function NotFound(){
    return (
        <div className="view not-found">
            <h1>Sorry, the requested page is not found!</h1>
            <Link to="/">Click here to head to the dashboard.</Link>
        </div>
    );
}