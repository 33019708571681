import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import Dashboard from "./../views/dashboard/Dashboard";
import Contacts from "./../views/contacts/Contacts";
import ContactsDetail from "../views/contacts/ContactsDetail";
import Invoices from "./../views/invoices/Invoices";
import InvoiceDetail from "./../views/invoices/InvoiceDetail";
import Reports from "./../views/reports/Reports";
import Settings from "./../views/settings/Settings";
import Subscription from "./../views/subscription/Subscription";
import Profile from "./../views/profile/Profile";
import Login from "./../views/login/Login";
import NotFound from "./../views/not-found/NotFound";

export default function Routes(){
    return (
        <Switch>
            <AuthenticatedRoute exact path="/" component={Dashboard} />
            <AuthenticatedRoute exact path="/Contacts" component={Contacts} />
            <AuthenticatedRoute exact path="/Contacts/:id" component={ContactsDetail} />
            <AuthenticatedRoute exact path="/Invoices" component={Invoices} />
            <AuthenticatedRoute exact path="/Invoices/:id" component={InvoiceDetail} />
            <AuthenticatedRoute path="/Reports" component={Reports} />
            <AuthenticatedRoute path="/Settings" component={Settings} />
            <AuthenticatedRoute path="/Subscription" component={Subscription} />
            <AuthenticatedRoute path="/Profile" component={Profile} />
            <UnauthenticatedRoute path="/Login" component={Login} />
            <Route component={NotFound} />
        </Switch>
    );
}