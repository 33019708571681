import React from "react";
import "./DropButton.css";

type DropOptions= {
    action:boolean,
    isLoading:boolean,
    items:Array<OptionItem>
}

type OptionItem ={
    label:string,
    path:string
}

export default function DropButton({action, isLoading, items}: DropOptions){
    return (
        <div className={`drop-button ${action? `actions` : ``}`}>
            <div className="inner">
                <a className="inner text-trim">Save Changes</a>
                <div className="drop">
                    <i className="fal fa-chevron-down"></i>
                    <ol>
                        <li>
                            <a className="text-trim">Save and publish</a>
                        </li>
                        <li>
                            <a className="text-trim">Delete</a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}