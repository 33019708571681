import React from "react";
import "./Subscription.css";

export default function Subscription(){
    return (
        <div className="view">
            <h1>Subscription!</h1>
            <span>Administer your account subscription and billing.</span>
        </div>
    );
}