import { IdentityService } from './../services/Identity.Service';

export function handleResponse(response:any) {
    const statusCode = response.status;

    if (statusCode==401){
        //Unauthorised - Force logout and reload
        console.log("Unauthorised. - Logging out and return to Login.")
        IdentityService.logout();
        window.location.reload();
    } 
    else if (statusCode==403){
        //Forbidden - User has no access to this.
        console.log("Forbidden. - Redirecting to Dashboard.")
        window.location.href="/";
    }

    return response.text().then((text:any) => {
        const data = text && JSON.parse(text);
        
        console.log(response);

        if (!response.ok){
            return Promise.reject(data);       
        }

        return data;
    });
}