import React from "react";
import List from "./components/List";
import "./../../styles/Tabs.css";

export default function Contacts(){    
    return (
        <div className="view">
            <ul className="tabs">
                <li className="item active">Customers</li>
                <li className="item">Suppliers</li>
                {/* <li className="add" title="Add a new category">
                    <i className="fal fa-plus-circle"></i>
                </li> */}
                <li className="item right">Archived</li>
            </ul>

            <List />            
        </div>
    );
}