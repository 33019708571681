import React from "react";
import { useParams } from "react-router";

interface Params {
    id:string
}

export default function ContactsDetail(){    
    const { id } = useParams<Params>();

    return (
        <div className="view">
            Contact detail for {id}
        </div>
    );
}