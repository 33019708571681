import config from "../../../config";
import React, { useEffect, useState } from "react";
import {SearchResponse} from "./List.d";
import avatar from "../../../shell/Avatar";
import { Link } from "react-router-dom";
import { handleResponse } from '../../../helpers/HandleResponse';
import { IdentityService } from "../../../services/Identity.Service";
import Moment from 'react-moment';
import Animation from './../../../shell/Animation';
import errorAnimation from './../../../animations/error.json';
import "./../../../styles/Grid.css";

export default function List(){
    const size = 20;
    const page = 1;
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [invoices, setInvoices] = useState<SearchResponse|null>(null);

    useEffect(() => {
        async function get(){
            await fetch(`${config.apiMock}/invoices?accountId=${IdentityService.getAccount()}&page=${page}&size=${size}`)
            .then(handleResponse)
            .then((a:SearchResponse)=>{
                setInvoices(a);
                setLoading(false);
                setError(false);
                return a;
            }).catch(a=>{
                setLoading(false);
                setError(true);
            });
        }

        get();
    }, []);

    return (
        <div className="grid">
            <ul>
                <li className="header">
                    <div>
                        <p>From:</p>
                        <sup>
                            <i className="fal fa-arrow-down"></i>
                        </sup>
                    </div>
                    <div>
                        <p>To:</p>
                        <sup></sup>
                    </div>
                    <div>
                        <p>Sub Total:</p>
                        <sup></sup>
                    </div>
                    <div>
                        <p>Taxes:</p>
                        <sup></sup>
                    </div>                        
                    <div>
                        <p>Total:</p>
                        <sup></sup>
                    </div> 
                    <div>
                        <p>Last Activity:</p>
                        <sup></sup>
                    </div>
                    <div className="actions">
                        <p></p>
                    </div>                       
                </li>      

                {isError ? 
                    <li className="error">
                    <div>
                        <Animation lottie={errorAnimation} displayHeight={350} displayWidth={450} />
                        <h3>Aww snap!</h3>
                        <span>It's embarrassing, we've seem to have hit a problem while fetching your details.<br />Sometimes, this may happen if you've lost your internet connection. In this case check your connection and try again.</span>
                    </div>
                </li>
                :
                    <></>
                }   

                {isLoading && !isError ?
                    [...Array(size)].map((a,b) =>
                        <li className="item" key={b}>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>
                            <div>
                                <p className="skeleton"></p>
                            </div>                            
                            <div className="actions">
                                <p className="skeleton"></p>
                            </div>
                        </li>
                    )                    
                :
                    invoices?.items.map((invoice, index)=>(
                        <li className="item" key={index}>
                            <div>
                                <p className="avatar">{avatar(invoice?.from.label, false)}</p>
                                <p>{invoice?.from.label}</p>
                            </div>
                            <div>
                                <p className="avatar">{avatar(invoice?.to?.label, false)}</p>
                                <p>{invoice?.to.label}</p>
                            </div>
                            <div>
                                <p>$ {invoice.subTotal.toFixed(2)}</p>
                            </div>
                            <div>
                                <p>$ {invoice.tax.toFixed(2)}</p>
                            </div>
                            <div>
                                <p>$ {invoice.total.toFixed(2)}</p>
                            </div>
                            <div>
                                <p>
                                    <Moment date={invoice.lastActivity} format="Do MMM YYYY [at] h:mm a" local />
                                </p>
                            </div>                            
                            <div className="actions">
                                <Link to={`/Invoices/${invoice.id}`}>
                                    <i className="fal fa-chevron-right"></i>
                                </Link>
                            </div>
                        </li>
                    ))
                }
            </ul>
            {!isLoading && invoices && invoices?.count > 0 ?
                <div className="stats">{invoices?.count} items found.</div>
            :
                <></>
            }
            {!isLoading && invoices && invoices?.count > size ?
                <ol>
                    <li className="active">1</li>
                    <li>2</li>
                    <li>3</li>
                </ol>
            :
                <></>
            }            
            <div className="clear"></div>
        </div>
    );
}