import config from "./../config";
import { handleResponse } from './../helpers/HandleResponse';
import {ProfileInterface, AuthenticateInterface} from './../state/Identity.d';

export const IdentityService = {
    login,
    logout,
    get,
    token,
    getAccount
};

function logout(){
    localStorage.removeItem('token');
}

function token(){
    return localStorage.getItem('token');
}

function getAccount(){
    return localStorage.getItem('account');
}

async function login(emailAddress:string, password:string){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            "email": emailAddress,
            "password": password })
    };

    return (await fetch(`${config.api}/identity`, requestOptions)
            .then(handleResponse)
            .then((a:AuthenticateInterface) => {
                // Store metadata into local storage.
                localStorage.setItem('token', a.token);
                
                return a;
            }));
}

async function get(){
    if (token()!=null){
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token()}`
            }
        };    

        return (await fetch(`${config.api}/profile`, requestOptions)
                .then(handleResponse)
                .then((a:ProfileInterface) => {
                    //Lookup the Account
                    var lookup = a.accounts.find(b=> b.id==getAccount());

                    if (lookup!=null && lookup!=undefined){
                        //Assign.
                        a.currentAccount = lookup;
                    }else{
                        //None - take first
                        a.currentAccount = a.accounts[0];
                    }

                    //Set current Account to localStorage
                    localStorage.setItem('account', a.currentAccount.id);

                    return a;
                }));
    }else{
        //No token available
        return (Promise.reject());
    }
}